import Home from "../pages/home/Home";
import Icvm from "../pages/icvm/Icvm";
import Atualidades from "../pages/atualidades/Atualidades";
import Projetos from "../pages/projetos/Projetos";
import Biblioteca from "../pages/biblioteca/Biblioteca";
import Conferencias from "../pages/conferencias/Conferencias";
import PostIndividual from "../pages/postIndividual/PostIndividual";
import Contactos from "../pages/contactos/Contactos";
import Pesquisa from "../pages/pesquisa/Pesquisa";
import ProjetoIndividual from "../pages/projetoIndividual/ProjetoIndividual";
import Atlas from "../pages/atlas/Atlas";
import ProjetoPostIndividual from "../pages/postIndividual/ProjetoPostIndividual";
import Certificados from "../pages/certificados/Certificados";

const routes = [
  {
    title: "home",
    path: "/",
    navBarPages: false,
    component: Home,
  },
  {
    title: "ICVM",
    path: "/icvm",
    navBarPages: true,
    component: Icvm,
  },
  {
    title: "Atividades",
    path: "/atividades",
    navBarPages: true,
    component: Projetos,
  },
  /* {
    title: "FAQ",
    path: "/faq",
    navBarPages: true,
    component: Faqs,
  }, */
  {
    title: "Notícias",
    path: "/noticias",
    navBarPages: true,
    component: Atualidades,
  },
  {
    title: "atualidade",
    path: "/noticia/:id",
    navBarPages: false,
    component: PostIndividual,
  },
  {
    title: "Biblioteca",
    path: "/biblioteca",
    navBarPages: true,
    component: Biblioteca,
  },
  {
    title: "Contactos",
    path: "/contactos",
    navBarPages: true,
    component: Contactos,
  },
  {
    title: "Pesquisa",
    path: "/pesquisa/:search",
    navBarPages: false,
    component: Pesquisa,
  },
  {
    title: "Atividade",
    path: "/atividade/:id",
    navBarPages: false,
    component: ProjetoIndividual,
  },
  {
    title: "Atividade",
    path: "/atividade/:id/:postId",
    navBarPages: false,
    component: ProjetoPostIndividual,
  },
  {
    title: "Certificado de Acessibilidade",
    description:
      "Avaliação e certificação de edifícios e espaços públicos em matéria de acessibilidade",
    path: "/atlas",
    navBarPages: false,
    projectList: true,
    image: require("../assets/banners/banner_ATLASDAACESSIBILIDADE.jpg"),
    component: Atlas,
    id: "atlas",
  },
  {
    title: "Certificados de Acessibilidade",
    description:
      "Et hictaque ium que optatem sum eum quam vid que lautUr? Me venimus acid quam.",
    path: "/atlas/certificados",
    navBarPages: false,
    component: Certificados,
  },
  {
    title: "Publicações",
    description:
      "Como entidade editora, assume-se como uma importante rótula de conhecimento e investigação",
    path: "/publicacoes",
    navBarPages: false,
    projectList: true,
    image: require("../assets/banners/publicacoes.jpg"),
    component: Biblioteca,
    id: "publicacoes",
  },
  {
    title: "Conferências",
    description:
      "Promoção de eventos nas suas áreas de atuação: tertúlias, conferências, seminários e workshops",
    path: "/conferencias",
    navBarPages: false,
    projectList: true,
    image: require("../assets/banners/conferenciasNew.jpeg"),
    component: Conferencias,
    id: "conferencias",
  },
  {
    title: "conferencia",
    path: "/conferencia/:id",
    navBarPages: false,
    component: PostIndividual,
  },
  {
    title: "Mapa do Site",
    path: "/atividade/3310",
    footer: true,
    /* component: Projetos, */
  },
];

const homeRoute = routes[0];

export { homeRoute, routes };
