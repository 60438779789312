import React, { useEffect, useState } from "react";
import "./Atualidades.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import {
  fetchPostsSucceeded,
  fetchMorePostsSucceeded,
} from "../../actions/actions";
import { fetchPostsPreview } from "../../sagas/posts";
import { getPostEntries, getPostEntriesTotal } from "../../selectors/posts";
import Button from "../../components/button/Button";
import AtualidadeItem from "../../components/atualidadeItem/AtualidadeItem";
import PageBanner from "../../components/pageBanner/PageBanner";
import {
  ATUALIDADES_CATEGORY_ID,
  ATUALIDADES_CATEGORY_ICVM_ID,
  ATUALIDADES_CATEGORY_GLOBAL_ID,
} from "../../constants/constants.api";
import Loader from "../../components/loader/Loader";
import FilterSection from "../../components/filterSection/FilterSection";

const bannerImage = require("../../assets/banners/atualidades.jpg");

const filtersArray = [
  { title: "ICVM", value: "icvm" },
  { title: "GLOBAL", value: "global" },
  { title: "VER TODOS", value: "" },
];

const PER_PAGE = (page) => (page === 1 ? 8 : 9);

const getCategoryNum = (filter) => {
  switch (filter) {
    case "":
      return `${ATUALIDADES_CATEGORY_ID}`;
    case "icvm":
      return `${ATUALIDADES_CATEGORY_ICVM_ID}`;
    case "global":
      return `${ATUALIDADES_CATEGORY_GLOBAL_ID}`;
    default:
      return `${ATUALIDADES_CATEGORY_ID}`;
  }
};

const Atualidades = ({
  onFetchPostsSucceeded,
  onFetchMorePostsSucceeded,
  posts,
  total,
}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    setLoading(true);
    const callback =
      page === 1 ? onFetchPostsSucceeded : onFetchMorePostsSucceeded;
    fetchPostsPreview(
      callback,
      getCategoryNum(filterValue),
      page,
      PER_PAGE(page),
      () => setLoading(false)
    );
  }, [
    onFetchPostsSucceeded,
    onFetchMorePostsSucceeded,
    page,
    setLoading,
    filterValue,
  ]);

  const hasMorePages = page * PER_PAGE(page) < total;
  return (
    <div className="atualidade-page">
      <PageBanner
        title="Notícias"
        description="Siga todas as notícias pelo mundo"
        image={bannerImage}
      />
      {posts && posts.length > 0 && (!loading || page > 1) ? (
        <div className="atualidade-content">
          <FilterSection
            filtersArray={filtersArray}
            filterValue={filterValue}
            onFilterClick={setFilterValue}
            orangeHover
          />
          <div className="atualidade-grid">
            {posts.map((ele, index) => (
              <AtualidadeItem
                isFirst={index === 0}
                post={ele}
                key={`atualidade-${index}`}
              />
            ))}
          </div>
          {hasMorePages &&
            (loading ? (
              <Loader loadMore />
            ) : (
              <div className="atualidade-more-section">
                <Button centered onClick={() => setPage(page + 1)}>
                  CARREGAR MAIS
                </Button>
              </div>
            ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getPostEntries,
  getPostEntriesTotal,
  (posts, total) => ({
    posts,
    total,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
      onFetchMorePostsSucceeded: fetchMorePostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Atualidades);
