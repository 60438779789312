import React, { useState } from "react";
import "./NavBar.css";
import { useLocation, Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { isNavBarItem } from "../../utils/utils";
import NavBarSearch from "./NavBarSearch";
import MenuMobile from "./MenuMobile";
import MenuPagesMobile from "./MenuPagesMobile";
import { useScrollHandler } from "../../utils/customHooks";

const NavBar = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const scroll = useScrollHandler();

  const { pathname: currentRoute } = useLocation();
  const darkMode = !isNavBarItem(currentRoute);
  return (
    <div
      className={`navbar ${darkMode ? "darkMode" : ""} ${
        scroll ? "smaller" : ""
      }`}
    >
      <div className="navbar-wrapper">
        <div className="navbar-logo-container">
          <Link to="/">
            <div
              className={`navbar-logo ${darkMode ? "darkMode" : ""} ${
                scroll ? "smaller" : ""
              }`}
            />
          </Link>
        </div>
        {!searchOpen ? (
          <div className="navbar-pages-section">
            {routes.map(({ path, title, navBarPages }) => {
              const active = currentRoute === path;
              return (
                navBarPages && (
                  <Link to={path} key={`key-${title.toLowerCase()}`}>
                    <div className="navbar-page-item">
                      <div
                        className={`navbar-page-item-title ${
                          darkMode ? "darkMode" : ""
                        } ${active ? "active" : ""}`}
                      >
                        {title}
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
            <div
              className={`navbar-pages-search ${darkMode ? "darkMode" : ""}`}
              onClick={() => setSearchOpen(!searchOpen)}
            />
            {/* Add the "Area Reservada" menu item here */}
            <a
                href="https://areareservada.icvm.pt/"
                target="_blank"
                className="reserved-area"
                rel="noopener noreferrer"
                key="area-reservada"
            >
              <div className="navbar-page-item">
                <div
                    className={`navbar-page-item-title ${
                        darkMode ? "darkMode" : ""
                    }`}
                >
                  Área Reservada
                </div>
              </div>
            </a>
          </div>
        ) : (
          <NavBarSearch
            onClick={() => setSearchOpen(!searchOpen)}
            darkMode={darkMode}
          />
        )}
        {!searchOpen && (
          <MenuMobile
            darkMode={darkMode}
            searchOpen={searchOpen}
            mobileMenuOpen={mobileMenuOpen}
            setSearchOpen={setSearchOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
        {mobileMenuOpen && (
          <MenuPagesMobile
            currentRoute={currentRoute}
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
