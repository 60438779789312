import React from "react";
import Fade from "react-reveal/Fade";
import "./PostBanner.css";

const PostBanner = ({ postType, title, description }) => (
  <Fade>
    <div className="postBanner-wrapper">
      <div className="postBanner-text-section">
        <div className="postBanner-postType">{postType}</div>
        <div
          className="postBanner-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="postBanner-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="postBanner-arrow-icon" />
    </div>
  </Fade>
);

export default PostBanner;
