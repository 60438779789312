const getHomeState = (state) => state.home;

export const getSlideshowImages = (state) =>
  getHomeState(state).slideshowImages;

export const getConferenciasPreview = (state) =>
  getHomeState(state).conferenciasPreview;

export const getHighlights = (state) => getHomeState(state).highlights;

export const getProjects = (state) => getHomeState(state).projects;

export const getAtualidadesPreview = (state) =>
  getHomeState(state).atualidadesPreview;
