import {
  FETCH_POSTS_SUCCEEDED,
  GET_POST_SUCCEEDED,
  FETCH_MORE_POSTS_SUCCEEDED,
} from "../actions/actions";

const initialState = {
  currentPost: null,
  entries: [],
  total: 0,
};

function posts(state = initialState, action) {
  switch (action.type) {
    case GET_POST_SUCCEEDED:
      return {
        ...state,
        currentPost: action.post,
      };
    case FETCH_POSTS_SUCCEEDED:
      return {
        ...state,
        entries: action.payload.posts,
        total: action.payload.total,
      };
    case FETCH_MORE_POSTS_SUCCEEDED:
      return {
        ...state,
        entries: [...state.entries, ...action.payload.posts],
        total: action.payload.total,
      };
    default:
      return state;
  }
}

export default posts;
