import React from "react";
import Fade from "react-reveal/Fade";
import "./AtualidadeItem.css";
import { Link } from "react-router-dom";
import { getFullDate } from "../../utils/utils";

const AtualidadeItem = ({ isFirst, post, key }) => {
  let image = post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  return (
    <Fade>
      <div className={`atualidade-item ${isFirst && "firstItem"}`} key={key}>
        <Link to={`/noticia/${post.id}`}>
          <div
            className="atualidade-item-image"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </Link>
        <div className="atualidade-item-content">
          <Link to={`/noticia/${post.id}`}>
            <div
              className={`atualidade-item-title ${isFirst && "firstItem"}`}
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            />
          </Link>
          <div className="atualidade-item-date">{getFullDate(post.date)}</div>
          <Link to={`/noticia/${post.id}`}>
            <div className="atualidade-item-button" />
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default AtualidadeItem;
