import React from "react";
import Fade from "react-reveal/Fade";
import "./Biblioteca.css";
import { trimString } from "../../utils/utils";

const renderBookInfoItem = (title, value) => (
  <div className="book-info-item-wrapper">
    <div className="book-info-item-title">{title}</div>
    <div className="book-info-item-value">{value}</div>
  </div>
);

const FeatureBook = ({ book }) => {
  let image = book && book._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";

  const info =
    book &&
    book.extra_info &&
    JSON.parse(JSON.parse(JSON.stringify(book.extra_info)));

  return (
    <Fade>
      <div className="feature-book-wrapper">
        <div className="feature-book-info-wrapper">
          <div
            className="feature-book-info-title"
            dangerouslySetInnerHTML={{ __html: trimString(book.title.rendered, 200, true) }}
          />
          <div
            className="feature-book-info-description"
            dangerouslySetInnerHTML={{ __html: book.content.rendered }}
          />
          <div className="feature-book-info-section">
            {renderBookInfoItem("Autor", info.autor)}
            {renderBookInfoItem("Edição", info.edicao)}
            {renderBookInfoItem("ISBN", info.isbn)}
            {renderBookInfoItem("Editora", info.editora)}
          </div>
        </div>
        <div
          className="feature-book-cover-wrapper"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </div>
    </Fade>
  );
};

export default FeatureBook;
