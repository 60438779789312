import React from "react";
import Fade from "react-reveal/Fade";
import "./ProjetoItem.css";
import { Link } from "react-router-dom";
import { getTagElement, trimString } from "../../utils/utils";

const ProjetoItem = ({ post, fixedPost }) => {
  let image = post && post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  image = fixedPost ? fixedPost.image : image;

  const postLink = fixedPost ? fixedPost.path : `/atividade/${post.id}`;

  const isExternalLink = post && post.external_link;

  return (
    <Fade>
      <div className="projeto-item">
        <div className="projeto-item-title">
          {fixedPost
            ? fixedPost.title
            : trimString(getTagElement(post.title.rendered, "p"), 50, true)}
        </div>
        <div className="projeto-item-description">
          {fixedPost
            ? fixedPost.description
            : trimString(getTagElement(post.excerpt.rendered, "p"), 150, true)}
        </div>
        {/* <div
        className="projeto-item-image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <Link
          to={fixedPost ? fixedPost.path : `/atividade/${post.id}`}
          className="projeto-item-button"
        />
      </div> */}
        <Link
          to={isExternalLink ? { pathname: post.external_link } : postLink}
          className="projeto-item-image"
          style={{
            backgroundImage: `url(${image})`,
          }}
          target={isExternalLink && "_blank"}
        >
          <div className="projeto-item-button" />
        </Link>
      </div>
    </Fade>
  );
};

export default ProjetoItem;
