import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import "./Slideshow.css";
import { Fade as SlideshowFade } from "react-slideshow-image";
import { getPost } from "../../sagas/posts";
import { SLIDESHOW_POST_ID } from "../../constants/constants.api";
import SlideshowItem from "./SlideshowItem";
import "react-slideshow-image/dist/styles.css";
import { getContentList } from "../../utils/pageBuilder";
import { parseSlideshow } from "../../utils/utils";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  prevArrow: <div className="slideshow-arrow left" />,
  nextArrow: <div className="slideshow-arrow right" />,
};

const Slideshow = ({ imageArray, onSuccess }) => {
  useEffect(() => {
    getPost(SLIDESHOW_POST_ID, (res) => {
      const figures = getContentList(res.content.rendered);
      const imagesArray = figures.map((ele) => parseSlideshow(ele));
      onSuccess(imagesArray);
    });
    /*fetchPostImages(SLIDESHOW_POST_ID, onSuccess);*/
  }, [onSuccess]);

  return (
    <Fade>
      <div className="slideshow-wrapper">
        <SlideshowFade {...properties}>
          {imageArray.map((image) => (
            <SlideshowItem image={image} />
          ))}
        </SlideshowFade>
      </div>
    </Fade>
  );
};

export default Slideshow;
