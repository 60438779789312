import { FETCH_PAGE_SUCCEEDED } from "../actions/actions";

const initialState = {
  currentPage: null,
};

function pages(state = initialState, action) {
  switch (action.type) {
    case FETCH_PAGE_SUCCEEDED:
      return {
        ...state,
        currentPage: action.page,
      };
    default:
      return state;
  }
}

export default pages;
