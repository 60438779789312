import React from "react";
import Fade from "react-reveal/Fade";
import { buildNewLinesFromString } from "../../utils/utils";
import "./DropdownQuestion.css";

const QuestionItem = ({ gray, isOpen, question, onOpenClick }) => (
  <Fade>
    <div className="question-item-wrapper">
      <div className="question-item-question-section">
        <div
          className={`question-item-plus ${isOpen ? "open" : ""}`}
          onClick={onOpenClick}
        />
        <div className={`question-item-question ${gray ? "gray" : ""}`}>
          {question.question}
        </div>
      </div>
      <div
        className={`question-item-answer ${isOpen ? "isOpen" : ""}`}
        dangerouslySetInnerHTML={{
          __html: buildNewLinesFromString(question.answer),
        }}
      />
    </div>
  </Fade>
);

export default QuestionItem;
