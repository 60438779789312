import React from "react";
import "./AtlasSubPages.css";
import niveisAcessibilidade from "../../../constants/niveisAcessibilidade";
import NivelAcessibilidade from "../../../components/NivelAcessibilidade/NivelAcessibilidade";

const NiveisAcessibilidade = () => (
  <div className="atlas-subpage-content">
    <div className="niveis-acessibilidade-wrapper">
      {niveisAcessibilidade.map((ele) => (
        <NivelAcessibilidade nivel={ele} />
      ))}
    </div>
    <div className="niveis-acessibilidade-description">
      O Certificado de Acessibilidade – ICVM e todos os materiais promocionais
      associados são da exclusiva responsabilidade do Instituto de Cidades e
      Vilas com Mobilidade. A sua utilização indevida será punida ao abrigo da
      legislação em vigor (Decreto-Lei n.o 63/85, de 14 de Março).
    </div>
  </div>
);

export default NiveisAcessibilidade;
