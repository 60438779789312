import React from "react";
import "./NavBar.css";

const MenuMobile = ({
  darkMode,
  searchOpen,
  mobileMenuOpen,
  setSearchOpen,
  setMobileMenuOpen,
}) => (
  <div className="nav-bar-menu-mobile">
    <div
      className={`nav-bar-search-button ${darkMode ? "darkMode" : ""}`}
      onClick={() => setSearchOpen(!searchOpen)}
    />
    <div
      className="nav-bar-menu-button"
      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
    />
  </div>
);

export default MenuMobile;
