import React, { useEffect, useState } from "react";
import "./Atlas.css";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import PageBanner from "../../components/pageBanner/PageBanner";
import { getPost } from "../../sagas/posts";
import { getCurrentPost } from "../../selectors/posts";
import { getPostSucceeded } from "../../actions/actions";
import { ATLAS_POST_ID } from "../../constants/constants.api";
import Loader from "../../components/loader/Loader";
import { getContentList, buildPageContent } from "../../utils/pageBuilder";

const bannerImage = require("../../assets/banners/banner_ATLASDAACESSIBILIDADE.jpg");

const Atlas = ({ onGetPostSucceeded, post }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPost(ATLAS_POST_ID, onGetPostSucceeded, () => setLoading(false));
  }, [onGetPostSucceeded, setLoading]);

  const postContentBuilt = post && getContentList(post.content.rendered);

  return (
    <div className="atlas-page">
      <PageBanner
        title="Certificado de Acessibilidade"
        description="Avaliação e certificação de edifícios e espaços públicos em matéria de acessibilidade"
        image={bannerImage}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="atlas-section">
          {post && (
            <div>
              {postContentBuilt ? (
                buildPageContent(postContentBuilt)
              ) : (
                <div
                  className="project-content-wrapper"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getCurrentPost, (post) => ({
  post,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPostSucceeded: getPostSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Atlas);
