import React from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

const MenuPagesMobile = ({
  currentRoute,
  mobileMenuOpen,
  setMobileMenuOpen,
}) => (
  <div className="nav-bar-menu-pages-mobile">
    {routes.map(({ path, title, navBarPages }) => {
      const active = currentRoute === path;
      return (
        navBarPages && (
          <Link
            to={path}
            key={`key-${title.toLowerCase()}`}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <div className="navbar-page-item">
              <div
                className={`navbar-page-item-title ${active ? "active" : ""}`}
              >
                {title}
              </div>
            </div>
          </Link>
        )
      );
    })}
    {/* Add the "Area Reservada" menu item here */}
    <a
        href="https://areareservada.icvm.pt/"
        target="_blank"
        className="reserved-area"
        rel="noopener noreferrer"
        key="area-reservada"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
    >
      <div className="navbar-page-item">
        <div className="navbar-page-item-title">Área Reservada</div>
      </div>
    </a>
  </div>
);

export default MenuPagesMobile;
