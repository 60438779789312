import React, { useEffect } from "react";
import "./Gallery.css";

const OpenGallery = ({ image, onCloseClick, onChangeImage }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onCloseClick();
      }
      if (event.keyCode === 39) {
        onChangeImage("next");
      }
      if (event.keyCode === 37) {
        onChangeImage("back");
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onCloseClick, onChangeImage]);

  return (
    <div className="open-gallery-wrapper" onClick={onCloseClick}>
      <div className="open-gallery-image-wrapper">
        <div className="open-gallery-image-close-wrapper">
          <div className="open-gallery-image-close" onClick={onCloseClick}>
            Fechar
          </div>
        </div>
        <div className="open-gallery-image-arrows">
          <div
            className="open-gallery-arrow left"
            onClick={(event) => {
              event.stopPropagation();
              onChangeImage("back");
            }}
          />
          <div
            className="open-gallery-image"
            style={{ backgroundImage: `url(${image})` }}
          />
          <div
            className="open-gallery-arrow right"
            onClick={(event) => {
              event.stopPropagation();
              onChangeImage("next");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenGallery;
