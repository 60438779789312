import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import "./SentenceBanner.css";

const SentenceBanner = ({ sentenceArray }) => {
  const [firstIndex, setFirstIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(1);

  useEffect(() => {
    const interval1 = setInterval(
      () =>
        setFirstIndex(
          firstIndex + 2 < sentenceArray.length ? firstIndex + 2 : 0
        ),
      15000
    );
    const interval2 = setInterval(
      () =>
        setSecondIndex(
          secondIndex + 2 < sentenceArray.length ? secondIndex + 2 : 1
        ),
      15000
    );
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [firstIndex, secondIndex, setFirstIndex, setSecondIndex, sentenceArray]);

  const firstSentence = sentenceArray[firstIndex];
  const secondSentence = sentenceArray[secondIndex];
  return (
    <Fade>
      <div className="sentenceBanner-wrapper">
        <div className="sentence-section-wrapper">
          <div className="sentence-section slideLeft">{firstSentence}</div>
          <div className="sentence-section slideLeft second">
            {secondSentence}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default SentenceBanner;
