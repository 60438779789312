import React from "react";
import "./FilterSection.css";

const FilterSection = ({
  filtersArray,
  filterValue,
  onFilterClick,
  columnGap,
  darkMode,
  orangeHover,
  width,
}) => (
  <div
    className="filter-section"
    style={{
      columnGap,
      width,
      gridTemplateColumns: `repeat(auto-fill, calc((100% /${filtersArray.length}) - 10px)`,
    }}
  >
    {filtersArray.map((filter, index) => (
      <div
        key={`filter-${index}`}
        className={`filter-button ${
          filterValue === filter.value ? "active" : ""
        } ${darkMode ? "darkMode" : ""} ${orangeHover ? "orangeHover" : ""}`}
        onClick={() => onFilterClick(filter.value)}
      >
        <div className="filter-button-text">{filter.title}</div>
      </div>
    ))}
  </div>
);

export default FilterSection;
