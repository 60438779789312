import React from "react";
import "./SearchItem.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { getTagElement, trimString } from "../../utils/utils";
import {
  ATUALIDADES_CATEGORY_ID,
  CONFERENCIAS_CATEGORY_ID,
} from "../../constants/constants.api";

const getLink = (post) => {
  if (post && post.categories.includes(ATUALIDADES_CATEGORY_ID)) {
    return `/noticia/${post.id}`;
  }
  if (post && post.categories.includes(CONFERENCIAS_CATEGORY_ID)) {
    return `/conferencia/${post.id}`;
  }
  return "";
};

const SearchItem = ({ post, showImage, hasBorder, fixedPost, isProject }) => {
  let image = post && post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  image = fixedPost ? fixedPost.image : image;

  const postLink = post && isProject ? `/atividade/${post.id}` : getLink(post);

  const link = fixedPost ? fixedPost.path : postLink

  const isExternalLink = post && post.external_link

  return (
    <Fade>
      <div className={`search-item ${hasBorder ? "hasBorder" : ""}`}>
        {showImage && (
          <Link
            to={isExternalLink ? { pathname: post.external_link } : link}
            className="search-item-image"
            style={{
              backgroundImage: `url(${image})`,
            }}
            target={isExternalLink && "_blank"}
          >
            <div
              /* to={fixedPost ? fixedPost.path : link} */
              className="search-item-button"
            />
          </Link>
        )}
        <div
          className="search-item-title"
          dangerouslySetInnerHTML={{
            __html: fixedPost
              ? fixedPost.title
              : trimString(getTagElement(post.title.rendered, "p"), 150, true),
          }}
        />
        <div
          className="search-item-description"
          dangerouslySetInnerHTML={{
            __html: fixedPost
              ? fixedPost.description
              : trimString(
                  getTagElement(post.excerpt.rendered, "p"),
                  200,
                  true
                ),
          }}
        />
        <div className="search-item-description">
          <Link
            to={isExternalLink ? { pathname: post.external_link } : link}
            className="search-item-link"
            target={isExternalLink && "_blank"}
          >
            Continuar a ler ↗
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default SearchItem;
