import React, { useState } from "react";
import "./DropdownQuestion.css";
import QuestionItem from "./QuestionItem";

const DropdownQuestion = ({ questions, gray }) => {
  const [openQuestions, setOpenQuestions] = useState([]);

  const onOpenClick = (index) => {
    let newArray = openQuestions.slice();
    if (newArray.includes(index)) {
      newArray = newArray.filter((item) => item !== index);
    } else {
      newArray.push(index);
    }
    setOpenQuestions(newArray);
  };

  return (
    <div className="dropdown-question-wrapper">
      {questions.map((question, index) => (
        <QuestionItem
          gray={gray}
          key={`question-item-${index}`}
          isOpen={openQuestions.includes(index)}
          question={question}
          onOpenClick={() => onOpenClick(index)}
        />
      ))}
    </div>
  );
};

export default DropdownQuestion;
