import React from "react";
import Fade from "react-reveal/Fade";
import "./ConferenciaItem.css";
import { Link } from "react-router-dom";
import {
  getDay,
  getMonthYear,
  trimString,
  getTagElement,
} from "../../utils/utils";

const ConferenciaItem = ({ post, darkMode, key }) => {
  let image = post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";

  const postInfo =
    post &&
    post.extra_info &&
    JSON.parse(JSON.parse(JSON.stringify(post.extra_info)));

  const futureDay = getDay(postInfo.futureDate || post.date);
  const futureMonthYear = getMonthYear(postInfo.futureDate || post.date);

  return (
    <Fade>
      <div
        className={`conferencia-item ${darkMode ? "darkMode" : ""}`}
        key={key}
      >
        <div className="conferencia-item-content">
          <div className="conferencia-item-date-section">
            <div className="conferencia-item-day">{futureDay}</div>
            <div className="conferencia-item-month-year">{futureMonthYear}</div>
          </div>
          <div
            className="conferencia-item-title"
            dangerouslySetInnerHTML={{
              __html: trimString(
                getTagElement(post.title.rendered, "p"),
                120,
                true
              ),
            }}
          />
        </div>
        <Link to={`conferencia/${post.id}`}>
          <div
            className="conferencia-item-image"
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <div className="conferencia-item-image-overlay" />
          </div>
        </Link>
      </div>
    </Fade>
  );
};

export default ConferenciaItem;
