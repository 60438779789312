import React from "react";
import "./Loader.css";

const Loader = ({ fullHeight, medium, loadMore }) => (
  <div
    className={`loader-wrapper ${fullHeight ? "fullHeight" : ""} ${
      medium ? "medium" : ""
    } ${loadMore ? "loadMore" : ""}`}
  >
    <div
      className={`loader-icon ${fullHeight ? "fullHeight" : ""} ${
        loadMore ? "loadMore" : ""
      }`}
    />
  </div>
);

export default Loader;
