/*
 * action types
 */
export const GET_POST_SUCCEEDED = "GET_POST_SUCCEEDED";

export const FETCH_POSTS_SUCCEEDED = "FETCH_POSTS_SUCCEEDED";

export const FETCH_MORE_POSTS_SUCCEEDED = "FETCH_MORE_POSTS_SUCCEEDED";

export const FETCH_PAGE_SUCCEEDED = "FETCH_PAGE_SUCCEEDED";

/*
 * homepage actions
 */
export const FETCH_SLIDESHOW_SUCCEEDED = "FETCH_SLIDESHOW_SUCCEEDED";
export const FETCH_HIGHLIGHTS_SUCCEEDED = "FETCH_HIGHLIGHTS_SUCCEEDED";
export const FETCH_PROJECTS_SUCCEEDED = "FETCH_PROJECTS_SUCCEEDED";
export const FETCH_CONFERENCIAS_PREVIEW_SUCCEEDED =
  "FETCH_CONFERENCIAS_PREVIEW_SUCCEEDED";
export const FETCH_ATUALIDADES_PREVIEW_SUCCEEDED =
  "FETCH_ATUALIDADES_PREVIEW_SUCCEEDED";

/*
 * atlas actions
 */

export const FETCH_ATRIBUIDOS_SUCCEEDED = "FETCH_ATRIBUIDOS_SUCCEEDED";
export const GET_CERTIFICADO_SUCCEEDED = "GET_CERTIFICADO_SUCCEEDED";
export const GET_NIVEIS_SUCCEEDED = "GET_NIVEIS_SUCCEEDED";
export const GET_PARCERIAS_SUCCEEDED = "GET_PARCERIAS_SUCCEEDED";

/*
 * action creators
 */

export const getPostSucceeded = (post) => ({
  type: GET_POST_SUCCEEDED,
  post,
});

export const fetchPostsSucceeded = (posts, total) => ({
  type: FETCH_POSTS_SUCCEEDED,
  payload: { posts, total },
});

export const fetchMorePostsSucceeded = (posts, total) => ({
  type: FETCH_MORE_POSTS_SUCCEEDED,
  payload: { posts, total },
});

export const fetchPageSucceeded = (page) => ({
  type: FETCH_PAGE_SUCCEEDED,
  page,
});

export const fetchSlideshowSucceeded = (images) => ({
  type: FETCH_SLIDESHOW_SUCCEEDED,
  images,
});

export const fetchHighlightsSucceeded = (posts) => ({
  type: FETCH_HIGHLIGHTS_SUCCEEDED,
  posts,
});

export const fetchProjectsSucceeded = (posts) => ({
  type: FETCH_PROJECTS_SUCCEEDED,
  posts,
});

export const fetchConferenciasPreviewSucceeded = (posts) => ({
  type: FETCH_CONFERENCIAS_PREVIEW_SUCCEEDED,
  posts,
});

export const fetchAtualidadesPreviewSucceeded = (posts) => ({
  type: FETCH_ATUALIDADES_PREVIEW_SUCCEEDED,
  posts,
});

export const fetchAtribuidosSucceeded = (posts) => ({
  type: FETCH_ATRIBUIDOS_SUCCEEDED,
  posts,
});

export const getCertificadoSucceeded = (post) => ({
  type: GET_CERTIFICADO_SUCCEEDED,
  post,
});

export const getNiveisSucceeded = (post) => ({
  type: GET_NIVEIS_SUCCEEDED,
  post,
});

export const getParceriasSucceeded = (post) => ({
  type: GET_PARCERIAS_SUCCEEDED,
  post,
});
