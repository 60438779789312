import React from "react";
import Fade from "react-reveal/Fade";
import "./PageBanner.css";

const PageBanner = ({ title, description, image }) => (
  <Fade>
    <div
      className="pageBanner-wrapper"
      style={{
        backgroundImage: `linear-gradient(
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.5) 75%,
      rgba(0, 0, 0, 0.65) 85%,
      rgba(0, 0, 0, 0.75) 95%,
      rgba(0, 0, 0, 0.8) 100%
  ), url(${image})`,
      }}
    >
      <div className="pageBanner-text-section">
        <div className="pageBanner-title">{title}</div>
        <div className="pageBanner-description">{description}</div>
      </div>
      <div className="pageBanner-arrow-icon" />
    </div>
  </Fade>
);

export default PageBanner;
