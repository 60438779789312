import React, { useState } from "react";
import "./NavBar.css";
import { useHistory } from "react-router-dom";

const NavBarSearch = ({ onClick, darkMode }) => {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  return (
    <div className="nav-bar-search-wrapper">
      <div
        className={`nav-bar-search-input-wrapper ${darkMode ? "darkMode" : ""}`}
      >
        <div
          className={`navbar-pages-search ${darkMode ? "darkMode" : ""}`}
          onClick={onClick}
        />
        <input
          type="text"
          className="nav-bar-search-input"
          value={searchValue}
          placeholder="Procurar..."
          onChange={(evt) => setSearchValue(evt.target.value)}
          onKeyPress={(evt) =>
            evt.key === "Enter" && history.push(`/pesquisa/${searchValue}`)
          }
        />
        <div className="nav-bar-search-close" onClick={onClick} />
      </div>
    </div>
  );
};

export default NavBarSearch;
