import React, { useState } from "react";
import "./Footer.css";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import FooterSection from "./FooterSection";
import { routes } from "../../constants/routes";
import { FACEBOOK_URL, VIMEO_URL } from "../../constants/constants.ui";

const Footer = () => {
  const { pathname: currentRoute } = useLocation();

  const [newsletterEmail, setNewsletterEmail] = useState("");

  const onSubscribeNewsletter = () => {
    axios({
      method: "POST",
      url:
        "https://icvm.api.icvm.pt/subscribe" /*"http://localhost:3002/subscribe"*/,
      data: {
        email: newsletterEmail,
      },
    }).then((response) => {
      if (!response.data.error) {
        alert("Newsletter subscribed successfully!");
      } else {
        alert(`Couldn't subscribe newsletter: ${response.data.msg}`);
      }
    });
  };

  return (
    <div className="footer">
      <FooterSection title="Menu" lightMode>
        {routes.map(({ path, title, navBarPages, footer }) => {
          const active = currentRoute === path;
          return (
            (navBarPages || footer) && (
              <Link to={path} key={`key-${title.toLowerCase()}`}>
                <div className="footer-page-item">
                  <div
                    className={`footer-page-item-title ${
                      active ? "active" : ""
                    }`}
                  >
                    {title}
                  </div>
                </div>
              </Link>
            )
          );
        })}
      </FooterSection>
      <FooterSection title="Contactos">
        <div className="footer-contact-section">
          <div>Rua do Passeio Alegre, nº 20</div>
          <div>4150-570 Porto - Portugal</div>
        </div>
        <div className="footer-contact-section">
          <div>41º08'51,70" N</div>
          <div>8º39'41,76" W</div>
        </div>
        <div className="footer-contact-section">
          <div>+351 228 328 115</div>
          <div>geral@institutodemobilidade.org</div>
        </div>
        <div className="footer-contact-section">
          <div>Subscreva a Newsletter</div>
          <div className="footer-contact-newsletter-wrapper">
            <input
              type="text"
              className="footer-contact-newsletter-input"
              value={newsletterEmail}
              placeholder="e-mail"
              onChange={(evt) => setNewsletterEmail(evt.target.value)}
            />
            <div
              className="footer-contact-newsletter-button"
              onClick={onSubscribeNewsletter}
            >
              ok
            </div>
          </div>
        </div>
      </FooterSection>
      <FooterSection title="Siga-nos">
        <div className="footer-social-wrapper">
          {/* <a href={LINKEDIN_URL}>
            <div className="footer-social-item linkedin" />
          </a> */}
          <a href={FACEBOOK_URL}>
            <div className="footer-social-item facebook" />
          </a>
          <a href={VIMEO_URL}>
            <div className="footer-social-item vimeo" />
          </a>
        </div>
      </FooterSection>
    </div>
  );
};

export default Footer;
