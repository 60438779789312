import React, { useEffect, useState } from "react";
import "./ProjetoIndividual.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { getPost } from "../../sagas/posts";
import { getPostSucceeded } from "../../actions/actions";
import { getCurrentPost } from "../../selectors/posts";
import { getTagElement, trimString } from "../../utils/utils";
import Loader from "../../components/loader/Loader";
import PageBanner from "../../components/pageBanner/PageBanner";
import { getContentList, buildPageContent } from "../../utils/pageBuilder";

const ProjetoIndividual = ({ onGetPostSucceeded, post }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPost(id, onGetPostSucceeded, () => setLoading(false));
  }, [id, onGetPostSucceeded, setLoading]);

  let image = post && post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";

  const postContentBuilt = post && getContentList(post.content.rendered);

  return (
    <div className="project-page">
      {loading ? (
        <Loader medium />
      ) : (
        <div className="project-content">
          {post && (
            <div className="project-content">
              <PageBanner
                title={trimString(post.title.rendered, 200, true)}
                description={trimString(
                  getTagElement(post.excerpt.rendered, "p"),
                  300,
                  true
                )}
                image={image}
              />
              {postContentBuilt ? (
                buildPageContent(postContentBuilt)
              ) : (
                <div
                  className="project-content-wrapper"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getCurrentPost, (post) => ({
  post,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPostSucceeded: getPostSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjetoIndividual);
