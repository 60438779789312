import {
  FETCH_SLIDESHOW_SUCCEEDED,
  FETCH_CONFERENCIAS_PREVIEW_SUCCEEDED,
  FETCH_ATUALIDADES_PREVIEW_SUCCEEDED,
  FETCH_HIGHLIGHTS_SUCCEEDED,
  FETCH_PROJECTS_SUCCEEDED,
} from "../actions/actions";

const initialState = {
  slideshowImages: [],
  highlights: [],
  projects: [],
  atualidadesPreview: [],
  conferenciasPreview: [],
};

function home(state = initialState, action) {
  switch (action.type) {
    case FETCH_SLIDESHOW_SUCCEEDED:
      return {
        ...state,
        slideshowImages: action.images,
      };
    case FETCH_HIGHLIGHTS_SUCCEEDED:
      return {
        ...state,
        highlights: action.posts,
      };
    case FETCH_PROJECTS_SUCCEEDED:
      return {
        ...state,
        projects: action.posts,
      };
    case FETCH_CONFERENCIAS_PREVIEW_SUCCEEDED:
      return {
        ...state,
        conferenciasPreview: action.posts,
      };
    case FETCH_ATUALIDADES_PREVIEW_SUCCEEDED:
      return {
        ...state,
        atualidadesPreview: action.posts,
      };
    default:
      return state;
  }
}

export default home;
