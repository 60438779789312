import React, { useEffect } from "react";
import "./PreviewSection.css";
import { Link } from "react-router-dom";
import { fetchPostsPreview } from "../../sagas/posts";
import Button from "../button/Button";

const PreviewSection = ({
  categoryId,
  darkMode,
  itemComponent,
  previewItems,
  route,
  onSuccess,
}) => {
  useEffect(() => {
    fetchPostsPreview(onSuccess, categoryId, 1, 3);
  }, [onSuccess, categoryId]);

  const { title, path } = route;
  return (
    <div className={`previewSection-wrapper ${darkMode ? "darkMode" : ""}`}>
      <div className={`previewSection-header ${darkMode ? "darkMode" : ""}`}>
        <div className={`previewSection-title ${darkMode ? "darkMode" : ""}`}>
          {title}
        </div>
        <div className="previewSection-more-button">
          <Link to={path}>
            <Button darkMode={!darkMode}>VER MAIS</Button>
          </Link>
        </div>
      </div>
      <div className="previewSection-grid">
        {previewItems &&
          previewItems.map((post, index) =>
            itemComponent({ post, darkMode: true, key: `previewItem-${index}` })
          )}
      </div>
      <div className="previewSection-mobile">
        <Link to={path}>
          <Button darkMode={!darkMode}>VER MAIS</Button>
        </Link>
      </div>
    </div>
  );
};

export default PreviewSection;
