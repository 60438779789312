import React from "react";
import "./Footer.css";

const FooterSection = ({ title, lightMode = false, children }) => (
  <div className={`footer-section ${lightMode && "lightMode"}`}>
    <div className="footer-section-wrapper">
      <div className="footer-section-title">{title}</div>
      <div className="footer-section-content">{children}</div>
    </div>
  </div>
);

export default FooterSection;
