import React, { useEffect, useState } from "react";
import "./Pesquisa.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import {
  fetchPostsSucceeded,
  fetchMorePostsSucceeded,
} from "../../actions/actions";
import { fetchPostSearchPreview } from "../../sagas/posts";
import { getPostEntries, getPostEntriesTotal } from "../../selectors/posts";
import Button from "../../components/button/Button";
import {
  ATUALIDADES_CATEGORY_ID,
  CONFERENCIAS_CATEGORY_ID,
} from "../../constants/constants.api";
import Loader from "../../components/loader/Loader";
import SearchItem from "../../components/searchItem/SearchItem";
import FilterSection from "../../components/filterSection/FilterSection";

const filtersArray = [
  { title: "TODOS", value: "" },
  { title: "NOTICIAS", value: "atualidades" },
  { title: "CONFERÊNCIAS", value: "conferencias" },
];

const getCategoryNum = (filter) => {
  switch (filter) {
    case "":
      return `${ATUALIDADES_CATEGORY_ID},${CONFERENCIAS_CATEGORY_ID}`;
    case "atualidades":
      return `${ATUALIDADES_CATEGORY_ID}`;
    case "conferencias":
      return `${CONFERENCIAS_CATEGORY_ID}`;
    default:
      return `${ATUALIDADES_CATEGORY_ID},${CONFERENCIAS_CATEGORY_ID}`;
  }
};

const PER_PAGE = 8;

const Pesquisa = ({
  onFetchPostsSucceeded,
  onFetchMorePostsSucceeded,
  posts,
  total,
}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const { search } = useParams();

  useEffect(() => {
    setLoading(true);
    const callback =
      page === 1 ? onFetchPostsSucceeded : onFetchMorePostsSucceeded;
    fetchPostSearchPreview(
      callback,
      getCategoryNum(filterValue),
      search,
      page,
      PER_PAGE,
      () => setLoading(false)
    );
  }, [
    onFetchPostsSucceeded,
    onFetchMorePostsSucceeded,
    filterValue,
    search,
    page,
  ]);

  const hasMorePages = page * PER_PAGE < total;
  return (
    <div className="pesquisa-page">
      {posts && posts.length > 0 && (!loading || page > 1) ? (
        <div className="pesquisa-content">
          <div className="pesquisa-title">Resultados da pesquisa</div>
          <FilterSection
            filtersArray={filtersArray}
            filterValue={filterValue}
            onFilterClick={setFilterValue}
            darkMode
          />
          <div className="pesquisa-list">
            {posts.map((ele, index) => (
              <SearchItem post={ele} key={`pesquisa-${index}`} />
            ))}
          </div>
          {hasMorePages && (
            <div className="pesquisa-more-section">
              {loading ? (
                <Loader loadMore />
              ) : (
                <Button centered onClick={() => setPage(page + 1)} darkMode>
                  CARREGAR MAIS
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <Loader medium />
      )}
      {/* {loading ? (
        <Loader medium />
      ) : (
        <div className="pesquisa-more-section">
          <Button centered onClick={() => setPage(page + 1)} darkMode>
            CARREGAR MAIS
          </Button>
        </div>
      )} */}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getPostEntries,
  getPostEntriesTotal,
  (posts, total) => ({
    posts,
    total,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
      onFetchMorePostsSucceeded: fetchMorePostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Pesquisa);
