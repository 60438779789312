import React, { useEffect, useState } from "react";
import "./Projetos.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { fetchPostsSucceeded } from "../../actions/actions";
import { fetchPostsPreview, getPost } from "../../sagas/posts";
import { getPostEntries } from "../../selectors/posts";
import PageBanner from "../../components/pageBanner/PageBanner";
import ProjetoItem from "../../components/projetoItem/ProjetoItem";
import {
  PROJECT_LIST_POST_ID,
  PROJETO_CATEGORY_ID,
} from "../../constants/constants.api";
import { routes } from "../../constants/routes";
import Loader from "../../components/loader/Loader";
import { mapProjectArray } from "../../utils/utils";

const bannerImage = require("../../assets/banners/projetos.jpg");

const Projetos = ({ onFetchPostsSucceeded, posts }) => {
  const [loading, setLoading] = useState(false);
  const [listProjects, setListProjects] = useState([]);

  const projects = routes.filter((ele) => ele.projectList);

  useEffect(
    () => {
      setLoading(true);

      if (
        posts &&
        posts.length > 0 &&
        posts[0].categories.includes(PROJETO_CATEGORY_ID) &&
        projects &&
        projects.length > 0
      ) {
        getPost(PROJECT_LIST_POST_ID, (post) => {
          const listProjects = mapProjectArray(
            post.content.rendered,
            posts,
            projects
          );
          setListProjects(listProjects);
        });
      }

      fetchPostsPreview(onFetchPostsSucceeded, PROJETO_CATEGORY_ID, 1, 30, () =>
        setLoading(false)
      );
    },
    // eslint-disable-next-line
    [onFetchPostsSucceeded, posts.length, projects.length, setLoading]
  );

  return (
    <div className="projeto-page">
      <PageBanner
        title="Atividades"
        description="Difundimos boas práticas em acessibilidade universal"
        image={bannerImage}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="projeto-grid">
          {listProjects.map((ele, index) => {
            const isFixedProject = !!ele.projectList;
            const props = { [isFixedProject ? "fixedPost" : "post"]: ele };
            return <ProjetoItem {...props} key={`projeto-${index}`} />;
          })}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getPostEntries, (posts) => ({
  posts,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projetos);
