import React, { useState } from "react";
import "./Gallery.css";
import ImageItem from "./ImageItem";
import OpenGallery from "./OpenGallery";
import { isMobile } from "../../utils/utils";

const Gallery = ({ imageArray }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const onChangeImage = (direction) => {
    let newIndex = openIndex;
    if (direction === "next") {
      newIndex = newIndex + 1 >= imageArray.length ? newIndex : newIndex + 1;
    } else if (direction === "back") {
      newIndex = newIndex - 1 < 0 ? newIndex : newIndex - 1;
    }
    setOpenIndex(newIndex);
  };

  return (
    <div className="gallery-wrapper">
      {openIndex !== null && imageArray && imageArray.length > 0 && (
        <OpenGallery
          image={imageArray[openIndex]}
          onCloseClick={() => setOpenIndex(null)}
          onChangeImage={onChangeImage}
        />
      )}
      {imageArray.map((image, index) => (
        <ImageItem
          image={image}
          onOpenImageClick={() => !isMobile() && setOpenIndex(index)}
        />
      ))}
    </div>
  );
};

export default Gallery;
