import React from "react";
import Fade from "react-reveal/Fade";
import "./HighlightItem.css";
import { Link } from "react-router-dom";
import { getFullDate } from "../../utils/utils";

const HighlightItem = ({ post, key }) => {
  let image = post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  return (
    <Fade>
      <div className="highlight-item" key={key}>
        <Link to={`/noticia/${post.id}`}>
          <div
            className="highlight-item-image"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <div className="highlight-item-content-section">
            <div className="highlight-item-content">
              <div
                className="highlight-item-title"
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
              <div className="highlight-item-date">
                {getFullDate(post.date)}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </Fade>
  );
};

export default HighlightItem;
