import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import "./MapPoints.css";
import MyMarker from "./MyMarker";

/* const points = [{ id: 1, title: "ICVM", lat: 41.1475178, lng: -8.66955 }]; */

const MapPoints = ({ points, defaultCenter, defaultZoom }) => {
  const [openTitle, setOpenTitle] = useState(null);

  return (
    <div className="map-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBxc_B-bCGYCcaHzliFZLTmMNHVsOt1_lk",
          language: "en",
          region: "US",
        }}
        defaultCenter={defaultCenter || { lat: 39.63809, lng: -7.7423018 }}
        defaultZoom={defaultZoom || 7}
      >
        {points.map(({ lat, lng, nivel, titulo }, index) => (
          <MyMarker
            key={`point-${index}`}
            lat={lat}
            lng={lng}
            nivel={nivel}
            titulo={titulo}
            openTitle={openTitle === titulo ? openTitle : null}
            setOpenTitle={setOpenTitle}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

export default MapPoints;
