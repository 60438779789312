import React from "react";
import "./Slideshow.css";
import { getTagElement } from "../../utils/utils";

const SlideshowItem = ({ image }) => {
  const { sourceUrl, title, description, link } = image;
  return (
    <div
      className="slideshow-item-wrapper"
      style={{
        backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.0) 0%,
        rgba(0, 0, 0, 0.5) 75%,
        rgba(0, 0, 0, 0.65) 85%,
        rgba(0, 0, 0, 0.75) 95%,
        rgba(0, 0, 0, 0.8) 100%
    ), url(${sourceUrl})`,
      }}
    >
      <div className="slideshow-text-section">
        <div
          className="slideshow-title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        >
          {/* {title} */}
        </div>
        <div
          className="slideshow-description"
          dangerouslySetInnerHTML={{
            __html: getTagElement(description, "p"),
          }}
        >
          {/* {getTagElement(description, "p")} */}
        </div>
        <a href={link} className="slideshow-link">
          Saber mais ↗
        </a>
      </div>
    </div>
  );
};

export default SlideshowItem;
