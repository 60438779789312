import React, { useEffect, useState } from "react";
import "./PostIndividual.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { useParams, useLocation } from "react-router-dom";
import { getPost } from "../../sagas/posts";
import { getPostSucceeded } from "../../actions/actions";
import { getCurrentPost } from "../../selectors/posts";
import PostBanner from "../../components/postBanner/PostBanner";
import { getPostType, getTagElement, trimString } from "../../utils/utils";
import Loader from "../../components/loader/Loader";

const PostIndividual = ({ onGetPostSucceeded, post }) => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPost(id, onGetPostSucceeded, () => setLoading(false));
  }, [id, onGetPostSucceeded, setLoading]);

  let image = post && post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";

  return (
    <div className="post-page">
      {loading ? (
        <Loader medium />
      ) : (
        <div className="post-content">
          {post && (
            <div className="post-content">
              <PostBanner
                postType={getPostType(pathname)}
                title={trimString(post.title.rendered, 200, true)}
                description={trimString(
                  getTagElement(post.excerpt.rendered, "p"),
                  350,
                  true
                )}
              />
              {image && (
                <div
                  className="post-content-image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}
              <div className="post-content-wrapper">
                <div
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getCurrentPost, (post) => ({
  post,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPostSucceeded: getPostSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostIndividual);
