import React, { useState } from "react";
import "./Atlas.css";
import FilterSection from "../../components/filterSection/FilterSection";
import Atribuidos from "./subPages/Atribuidos";
import CertificadaIcvm from "./subPages/CertificadoIcvm";
import NiveisAcessibilidade from "./subPages/NiveisAcessibilidade";
import RegulamentoAdesao from "./subPages/RegulamentoAdesao";
import Parcerias from "./subPages/Parcerias";

const subpagesArray = [
  { title: "ATRIBUÍDOS", value: "atribuidos" },
  { title: "O CERTIFICADO ICVM", value: "certificado" },
  { title: "NÍVEIS DE ACESSIBILIDADE", value: "niveis" },
  { title: "REGULAMENTO E ADESÃO", value: "regulamento" },
  { title: "PARCERIAS", value: "parcerias" },
];

const renderSubPage = (subpage) => {
  switch (subpage) {
    case "atribuidos":
      return <Atribuidos />;
    case "certificado":
      return <CertificadaIcvm />;
    case "niveis":
      return <NiveisAcessibilidade />;
    case "regulamento":
      return <RegulamentoAdesao />;
    case "parcerias":
      return <Parcerias />;
    default:
      return null;
  }
};

const AtlasSubPages = () => {
  const [subpageOpen, setSubPageOpen] = useState("atribuidos");

  return (
    <div className="atlas-entidades-section">
      <FilterSection
        filtersArray={subpagesArray}
        filterValue={subpageOpen}
        onFilterClick={setSubPageOpen}
        columnGap="12px"
        darkMode
        orangeHover
        width="90%"
      />
      {subpageOpen && renderSubPage(subpageOpen)}
    </div>
  );
};

export default AtlasSubPages;
