import React, { useEffect, useState } from "react";
import "./Biblioteca.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import {
  fetchPostsSucceeded,
  fetchMorePostsSucceeded,
} from "../../actions/actions";
import { fetchPostsPreview } from "../../sagas/posts";
import { getPostEntries, getPostEntriesTotal } from "../../selectors/posts";
import PageBanner from "../../components/pageBanner/PageBanner";
import FeatureBook from "./FeatureBook";
import Button from "../../components/button/Button";
import BookItem from "../../components/bookItem/BookItem";
import Loader from "../../components/loader/Loader";
import { getBookCategory, isTablet } from "../../utils/utils";
import {
  LIVROS_CATEGORY_ID,
  PUBLICACAO_CATEGORY_ID,
} from "../../constants/constants.api";
import FilterSection from "../../components/filterSection/FilterSection";

const bannerImageBiblioteca = require("../../assets/banners/bibliotecas.jpg");
const bannerImagePublicacoes = require("../../assets/banners/publicacoes.jpg");

const getBannerInfo = (categoryId) => {
  if (categoryId === LIVROS_CATEGORY_ID)
    return {
      title: "Biblioteca",
      description: "Livros e catálogos pelo mundo",
      image: bannerImageBiblioteca,
    };
  if (categoryId === PUBLICACAO_CATEGORY_ID)
    return {
      title: "Publicações",
      description:
        "Como entidade editora, assume-se como uma importante rótula de conhecimento e investigação",
      image: bannerImagePublicacoes,
    };
  return null;
};

const scrollToContent = () => {
  const y = isTablet() ? 570 : 650;
  scroll.scrollTo(y);
};

const PER_PAGE = 8;

const filtersArray = [
  { title: "PUBLICAÇÕES", value: PUBLICACAO_CATEGORY_ID },
  { title: "DOCUMENTOS", value: LIVROS_CATEGORY_ID },
];

const Biblioteca = ({
  onFetchPostsSucceeded,
  onFetchMorePostsSucceeded,
  posts,
  total,
}) => {
  const { pathname } = useLocation();
  const category = getBookCategory(pathname);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filterValue, setFilterValue] = useState(category);

  const bannerInfo = getBannerInfo(category);

  useEffect(() => {
    setLoading(true);
    const callback =
      page === 1 ? onFetchPostsSucceeded : onFetchMorePostsSucceeded;
    fetchPostsPreview(callback, filterValue, page, PER_PAGE, () =>
      setLoading(false)
    );
  }, [
    onFetchPostsSucceeded,
    onFetchMorePostsSucceeded,
    page,
    setLoading,
    category,
    filterValue
  ]);

  const featureBook =
    posts && posts.length > 0 && !loading ? posts[selectedIndex] : null;

  const hasMorePages = page * PER_PAGE < total;

  const isPublicacoes = category === PUBLICACAO_CATEGORY_ID

  return (
    <div className="biblioteca-page">
      <PageBanner
        title={bannerInfo.title}
        description={bannerInfo.description}
        image={bannerInfo.image}
      />
      {posts && posts.length > 0 && (!loading || page > 1) ? (
        <div>
          {!isPublicacoes && <FilterSection
            filtersArray={filtersArray}
            filterValue={filterValue}
            onFilterClick={setFilterValue}
            orangeHover
            darkMode
          />}
          {featureBook && <FeatureBook book={featureBook} />}
          <div className="biblioteca-grid-section">
            <div className="biblioteca-title">Outras publicações</div>
            <div className="biblioteca-grid">
              {posts.map((ele, index) => (
                <BookItem
                  post={ele}
                  key={`biblioteca-${index}`}
                  onClick={() => {
                    setSelectedIndex(index);
                    scrollToContent();
                  }}
                />
              ))}
            </div>
          </div>
          {hasMorePages &&
            (loading ? (
              <Loader loadMore />
            ) : (
              <div className="biblioteca-more-section">
                <Button centered onClick={() => setPage(page + 1)}>
                  CARREGAR MAIS
                </Button>
              </div>
            ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getPostEntries,
  getPostEntriesTotal,
  (posts, total) => ({
    posts,
    total,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
      onFetchMorePostsSucceeded: fetchMorePostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Biblioteca);
