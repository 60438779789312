import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import "./AtribuidoItem.css";
import { getNivelAcessibilidade } from "../../utils/utils";

const renderNivel = (nivel, darkMode) => (
  <div className="atribuido-item-nivel-wrapper">
    <div className={`atribuido-item-triangle ${darkMode ? "darkMode" : ""}`} />
    <div
      className={`atribuido-item-nivel-container ${darkMode ? "darkMode" : ""}`}
    >
      <div className="atribuido-item-nivel">
        <div
          className="atribuido-item-nivel-image"
          style={{ backgroundImage: `url(${nivel.image})` }}
        />
        <div className="atribuido-item-nivel-title">{nivel.title}</div>
      </div>
    </div>
  </div>
);

const AtribuidoItem = ({ isFirst, post, key, darkMode }) => {
  const [isOpen, setOpen] = useState(false);
  let image = post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  const postInfo =
    post &&
    post.extra_info &&
    JSON.parse(JSON.parse(JSON.stringify(post.extra_info)));

  const nivel = postInfo && getNivelAcessibilidade(postInfo.nivel);
  return (
    <Fade>
      <div className={`atribuido-item ${isFirst && "firstItem"}`} key={key}>
        <div
          className={`atribuido-item-image ${isOpen ? "isOpen" : ""}`}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <div className="atribuido-item-content">
          <div
            className={`atribuido-item-title ${isFirst && "firstItem"}`}
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
          <div
            className="atribuido-item-description"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
          <div
            className={`atribuido-item-description-section ${
              isOpen ? "isOpen" : ""
            }`}
          >
            <div className="atribuido-item-description">
              <a
                className="atribuido-item-link"
                target="_blank"
                rel="noopener noreferrer"
                href={postInfo.certificado_link}
              >
                Ver Certificado↗
              </a>
              {/* <a
                className="atribuido-item-link"
                target="_blank"
                rel="noopener noreferrer"
                href={postInfo.relatorio_link}
              >
                Ver Relatório↗
              </a> */}
            </div>
            <div className="atribuido-item-description">
              {nivel && renderNivel(nivel, darkMode)}
            </div>
          </div>
          <div
            className={`atribuido-item-button ${isOpen ? "minus" : "plus"}`}
            onClick={() => setOpen(!isOpen)}
          />
        </div>
      </div>
    </Fade>
  );
};

export default AtribuidoItem;
