import React from "react";
import CountUp from "react-countup";
import "./NumbersIcvm.css";

const renderItem = (item) => (
  <div className="number-item-wrapper">
    <div
      className="number-item-image"
      style={{ backgroundImage: `url(${item.image})` }}
    />
    <div className="number-item-number">
      <CountUp delay={0.8} end={item.number} duration={4} />
    </div>
    <div className="number-item-text">{item.text}</div>
  </div>
);

const NumbersIcvm = ({ numbers }) => (
  <div className="numbers-icvm-wrapper">
    {numbers.map((ele) => renderItem(ele))}
  </div>
);

export default NumbersIcvm;
