import React, { useEffect, useState } from "react";
import "./AtlasSubPages.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { fetchAtribuidosSucceeded } from "../../../actions/actions";
import { CERTIFICADO_CATEGORY_ID } from "../../../constants/constants.api";
import { fetchPostsPreview } from "../../../sagas/posts";
import { getAtribuidos } from "../../../selectors/atlas";
import Loader from "../../../components/loader/Loader";
import AtribuidoItem from "../../../components/atribuidoItem/AtribuidoItem";
import Button from "../../../components/button/Button";

const Atribuidos = ({ onFetchAtribuidosSucceeded, posts }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    fetchPostsPreview(
      onFetchAtribuidosSucceeded,
      CERTIFICADO_CATEGORY_ID,
      1,
      3,
      () => setLoading(false)
    );
  }, [onFetchAtribuidosSucceeded, setLoading]);

  return (
    <div>
      {loading ? (
        <Loader medium />
      ) : (
        <div className="atlas-subpage-content">
          {posts && posts.length > 0 && (
            <div>
              <div className="atribuidos-grid">
                {posts.map((ele, index) => (
                  <AtribuidoItem post={ele} key={`atribuidos-${index}`} />
                ))}
              </div>
              <div className="atribuidos-more-section">
                <Button
                  centered
                  onClick={() => history.push("/atlas/certificados")}
                >
                  VER TODOS
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(getAtribuidos, (posts) => ({
  posts,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchAtribuidosSucceeded: fetchAtribuidosSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Atribuidos);
