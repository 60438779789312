import React, { useEffect } from "react";
import "./Home.css";
import { fetchPostsPreview } from "../../sagas/posts";
import {
  ATUALIDADES_CATEGORY_ID,
  CONFERENCIAS_CATEGORY_ID,
} from "../../constants/constants.api";
import HighlightItem from "../../components/highlightItem/HighlightItem";

const HighlightSection = ({ highlights, onSuccess }) => {
  useEffect(() => {
    fetchPostsPreview(
      onSuccess,
      `${ATUALIDADES_CATEGORY_ID}+${CONFERENCIAS_CATEGORY_ID}`,
      1,
      3
    );
  }, [onSuccess]);

  return (
    <div className="highlights-wrapper">
      <div className="highlights-header">
        <div className="highlights-title">DESTAQUES</div>
      </div>
      <div className="highlights-content">
        {highlights && highlights.map((post) => <HighlightItem post={post} />)}
      </div>
    </div>
  );
};

export default HighlightSection;
