import React from "react";
import "./AppContainer.css";
import NavBar from "../navBar/NavBar";
import Footer from "../footer/Footer";

const AppContainer = ({ children }) => (
  <div className="appContainer">
    <NavBar />
    <div className="appContainer-content">{children}</div>
    <Footer />
  </div>
);

export default AppContainer;
