import React from "react";
import "./Contactos.css";
import PageBanner from "../../components/pageBanner/PageBanner";
import ContactosForm from "./ContactosForm";

const bannerImage = require("../../assets/banners/banner_CONTACTOS.jpg");

const Contactos = () => {
  /* const [newsletterInput, setNewsletterInput] = useState(''); */

  return (
    <div className="contactos-page">
      <PageBanner
        title="Contactos"
        description="Entre em contacto connosco."
        image={bannerImage}
      />
      <div className="contactos-section">
        <div className="contactos-title">Localização</div>
        <div className="contactos-map">
          <iframe
            title="ICVM"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.398375708251!2d-8.663815884581657!3d41.14765777928688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f87a10ca805%3A0xcbf6fc23835df6b8!2sR.%20do%20Passeio%20Alegre%2020%2C%204150-589%20Porto!5e0!3m2!1spt-PT!2spt!4v1637428998922!5m2!1spt-PT!2spt"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0, width: "100%" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
          {/* 
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.398375708251!2d-8.663815884581657!3d41.14765777928688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f87a10ca805%3A0xcbf6fc23835df6b8!2sR.%20do%20Passeio%20Alegre%2020%2C%204150-589%20Porto!5e0!3m2!1spt-PT!2spt!4v1637428998922!5m2!1spt-PT!2spt" 
          width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
        </div>
        <div className="contactos-feedback-section">
          <div className="contactos-feedback-wrapper">
            <div className="contactos-feedback-left-wrapper">
              <div className="contactos-feedback-title">
                Entre em contacto connosco
              </div>
              Caso pretenda entrar em contacto connosco utilize o formulário ao
              lado.
              {/* <div className="contactos-feedback-regulation">
                Ver Regulamento ↗
              </div> */}
              <div className="contactos-feedback-subtitle">Morada</div>
              Rua do Passeio Alegre, nº 20
              <br />
              4150-570 Porto – Portugal
              <div className="contactos-feedback-subtitle">Coordenadas</div>
              41º08'51,70" N <br />
              8º39'41,76" W
              <div className="contactos-feedback-subtitle">Email</div>
              geral@institutodemobilidade.org
              {/* <div className="contactos-feedback-subtitle">Tel/Fax</div>
              (+351) 228 328 115 */}
              {/* <div className="contactos-feedback-subtitle">Newsletter</div>
              <input
                type="text"
                className="contact-input newsletter"
                value={newsletterInput}
                placeholder='Email'
                onChange={(evt) => setNewsletterInput(evt.target.value)}
              /> */}
            </div>
            <div className="contactos-feedback-right-wrapper">
              <ContactosForm subject="Contacto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactos;
