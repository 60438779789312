import React from "react";
import "./BookItem.css";
import { trimString, getTagElement } from "../../utils/utils";

const BookItem = ({ post, key, onClick }) => {
  let image = post._embedded["wp:featuredmedia"];
  image = image ? image[0].source_url : "";
  return (
    <div className="book-item" onClick={onClick} key={key}>
      <div
        className="book-item-image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="book-item-image-overlay" />
      </div>
      <div className="book-item-content-section">
        <div className="book-item-content">
          <div className="book-item-title" 
            dangerouslySetInnerHTML={{ __html: trimString(getTagElement(post.title.rendered, "p"), 25, true) }} 
          />
          <div className="book-item-description"
            dangerouslySetInnerHTML={{ __html: trimString(getTagElement(post.excerpt.rendered, "p"), 50, true) }} 
          />
        </div>
      </div>
    </div>
  );
};

export default BookItem;
