import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import home from "./home";
import pages from "./pages";
import posts from "./posts";
import atlas from "./atlas";
import history from "../store/history";

const createRootReducer = combineReducers({
  router: connectRouter(history),
  home,
  pages,
  posts,
  atlas,
});

export default createRootReducer;
