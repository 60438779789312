import {
  FETCH_ATRIBUIDOS_SUCCEEDED,
  GET_CERTIFICADO_SUCCEEDED,
  GET_NIVEIS_SUCCEEDED,
  GET_PARCERIAS_SUCCEEDED,
} from "../actions/actions";

const initialState = {
  atribuidos: [],
  certificado: null,
  niveis: null,
  parcerias: null,
};

function home(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATRIBUIDOS_SUCCEEDED:
      return {
        ...state,
        atribuidos: action.posts,
      };
    case GET_CERTIFICADO_SUCCEEDED:
      return {
        ...state,
        certificado: action.post,
      };
    case GET_NIVEIS_SUCCEEDED:
      return {
        ...state,
        niveis: action.post,
      };
    case GET_PARCERIAS_SUCCEEDED:
      return {
        ...state,
        parcerias: action.post,
      };
    default:
      return state;
  }
}

export default home;
