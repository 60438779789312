import React, { useState } from "react";
import axios from "axios";
import "./Contactos";

const renderInput = (placeholder, key, value, onChange) => (
  <input
    type="text"
    className="contact-input"
    value={value}
    placeholder={placeholder}
    onChange={(evt) => onChange(key, evt.target.value)}
  />
);

const ContactosForm = ({ subject }) => {
  const [formInput, setFormInput] = useState({
    nome: "",
    entidade: "",
    atividade: "",
    nif: "",
    mensagem: "",
    telefone: "",
    email: "",
  });

  const handleInputChange = (key, value) => {
    setFormInput({ ...formInput, [key]: value });
  };

  const onClickSend = () => {
    axios({
      method: "POST",
      url: /* "http://localhost:3002/send" */ "https://icvm.api.icvm.pt/send",
      data: {
        nome: formInput.nome,
        entidade: formInput.entidade,
        atividade: formInput.atividade,
        nif: formInput.nif,
        mensagem: formInput.mensagem,
        telefone: formInput.telefone,
        email: formInput.email,
        subject,
      },
    }).then((response) => {
      if (!response.data.error) {
        alert("Message Sent.");
      } else {
        alert(`Message failed to send: ${response.data.msg}`);
      }
    });
  };

  return (
    <div className="contact-form-wrapper">
      {renderInput("Nome", "nome", formInput.nome, handleInputChange)}
      {renderInput(
        "Entidade",
        "entidade",
        formInput.entidade,
        handleInputChange
      )}
      {renderInput(
        "Atividade",
        "atividade",
        formInput.atividade,
        handleInputChange
      )}
      {renderInput("NIF", "nif", formInput.nif, handleInputChange)}
      {renderInput(
        "Mensagem",
        "mensagem",
        formInput.mensagem,
        handleInputChange
      )}
      <div className="contact-split-input-row">
        {renderInput(
          "Telefone",
          "telefone",
          formInput.telefone,
          handleInputChange
        )}
        {renderInput("Email", "email", formInput.email, handleInputChange)}
      </div>
      <div className="contact-button-send" onClick={() => onClickSend()}>
        ENVIAR
      </div>
    </div>
  );
};

export default ContactosForm;
