import React from "react";
import "react-app-polyfill/stable";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import store from "./store/configureStore";
import AppContainer from "./components/appContainer/AppContainer";
import { routes } from "./constants/routes";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

const App = () => (
  <Provider store={store}>
    <Router>
      <ScrollToTop>
        <AppContainer>
          <Switch>
            {routes.map(
              ({ path, component }, index) =>
                component && (
                  <Route
                    exact
                    path={path}
                    component={component}
                    key={`route-${index}`}
                  />
                )
            )}
          </Switch>
        </AppContainer>
      </ScrollToTop>
    </Router>
  </Provider>
);

export default App;
