import React from "react";
import "./Button.css";

const Button = ({ darkMode, centered, onClick, children }) => (
  <div
    className={`button-wrapper ${darkMode ? "darkMode" : ""} ${
      centered ? "centered" : ""
    }`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Button;
