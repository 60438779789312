import React from "react";
import "./Gallery.css";

const ImageItem = ({ image, onOpenImageClick }) => (
  <div
    className="gallery-image-item"
    style={{ backgroundImage: `url(${image})` }}
    onClick={onOpenImageClick}
  >
    <div className="gallery-image-item-button" />
  </div>
);

export default ImageItem;
