import React, { useEffect, useState } from "react";
import "./Icvm.css";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import PageBanner from "../../components/pageBanner/PageBanner";
import { getPost } from "../../sagas/posts";
import { getContentList, buildPageContent } from "../../utils/pageBuilder";
import { getCurrentPost } from "../../selectors/posts";
import { getPostSucceeded } from "../../actions/actions";
import Loader from "../../components/loader/Loader";

const bannerImage = require("../../assets/banners/banner3.jpg");

const Icvm = ({ post, onGetPostSucceeded }) => {
  const postId = 2369;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPost(postId, onGetPostSucceeded, () => setLoading(false));
  }, [postId, onGetPostSucceeded]);

  const postContentBuilt = post && getContentList(post.content.rendered);

  return (
    <div className="icvm-page">
      <div className="icvm-content">
        <div className="icvm-content">
          <PageBanner
            title="ICVM"
            description="Difundimos boas práticas em acessibilidade universal."
            image={bannerImage}
          />
          <div className="icvm-page">
            {loading ? (
              <Loader />
            ) : (
              <div className="icvm-content">
                {postContentBuilt && post ? (
                  buildPageContent(postContentBuilt)
                ) : (
                  <div />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = createSelector(getCurrentPost, (post) => ({
  post,
}));

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetPostSucceeded: getPostSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Icvm);
