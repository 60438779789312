import React, { useEffect, useState } from "react";
import "./Home.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import {
  fetchSlideshowSucceeded,
  fetchAtualidadesPreviewSucceeded,
  fetchConferenciasPreviewSucceeded,
  fetchProjectsSucceeded,
  fetchHighlightsSucceeded,
} from "../../actions/actions";
import Slideshow from "../../components/slideshow/Slideshow";
import {
  getSlideshowImages,
  getConferenciasPreview,
  getAtualidadesPreview,
  getHighlights,
  getProjects,
} from "../../selectors/home";
import PreviewSection from "../../components/previewSection/PreviewSection";
import {
  CONFERENCIAS_CATEGORY_ID,
  ATUALIDADES_CATEGORY_ID,
} from "../../constants/constants.api";
import ConferenciaItem from "../../components/conferenciaItem/ConferenciaItem";
import AtualidadeItem from "../../components/atualidadeItem/AtualidadeItem";
import SentenceBanner from "../../components/sentenceBanner/SentenceBanner";
import ProjectsSection from "./ProjectsSection";
import HighlightSection from "./HighlightSection";
import Loader from "../../components/loader/Loader";

const routeConferencias = {
  title: "Conferências",
  path: "/conferencias",
};
const routeAtualidade = { title: "Notícias", path: "/noticias" };

const Home = ({
  atualidadesPreview,
  conferenciasPreview,
  slideshowImages,
  highlights,
  projects,
  onFetchAtualidadesSucceeded,
  onFetchConferenciasSucceeded,
  onFetchHighlightsSucceeded,
  onFetchProjectsSucceeded,
  onFetchSlideshowSucceded,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (
      atualidadesPreview.length > 0 &&
      conferenciasPreview.length > 0 &&
      slideshowImages.length > 0 &&
      highlights.length > 0 &&
      projects.length > 0
    )
      setLoading(false);
  }, [
    atualidadesPreview.length,
    conferenciasPreview.length,
    slideshowImages.length,
    highlights.length,
    projects.length,
    setLoading,
  ]);

  return (
    <div className="home-page">
      {loading ? (
        <Loader fullHeight />
      ) : (
        <div className="home-content">
          <Slideshow
            imageArray={slideshowImages}
            onSuccess={onFetchSlideshowSucceded}
          />
          <div className="home-projects-highlights-container">
            <div className="home-projects-highlights-wrapper">
              <ProjectsSection
                projects={projects}
                onSuccess={onFetchProjectsSucceeded}
              />
              <HighlightSection
                highlights={highlights}
                onSuccess={onFetchHighlightsSucceeded}
              />
            </div>
          </div>
          <PreviewSection
            darkMode
            categoryId={ATUALIDADES_CATEGORY_ID}
            itemComponent={AtualidadeItem}
            previewItems={atualidadesPreview}
            route={routeAtualidade}
            onSuccess={onFetchAtualidadesSucceeded}
          />
          <PreviewSection
            categoryId={CONFERENCIAS_CATEGORY_ID}
            itemComponent={ConferenciaItem}
            previewItems={conferenciasPreview}
            route={routeConferencias}
            onSuccess={onFetchConferenciasSucceeded}
          />
          <SentenceBanner
            sentenceArray={[
              "Sensibilizar, informar e formar técnicos.",
              "Uma nova cultura de mobilidade.",
              "Construção de vilas e cidades inclusivas.",
              "Territórios sociais de mobilidade.",
            ]}
          />
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getAtualidadesPreview,
  getConferenciasPreview,
  getHighlights,
  getProjects,
  getSlideshowImages,
  (
    atualidadesPreview,
    conferenciasPreview,
    highlights,
    projects,
    slideshowImages
  ) => ({
    atualidadesPreview,
    conferenciasPreview,
    highlights,
    projects,
    slideshowImages,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchAtualidadesSucceeded: fetchAtualidadesPreviewSucceeded,
      onFetchConferenciasSucceeded: fetchConferenciasPreviewSucceeded,
      onFetchHighlightsSucceeded: fetchHighlightsSucceeded,
      onFetchProjectsSucceeded: fetchProjectsSucceeded,
      onFetchSlideshowSucceded: fetchSlideshowSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
