import React, { useEffect, useState } from "react";
import "./Home.css";
import SearchItem from "../../components/searchItem/SearchItem";
import { routes } from "../../constants/routes";
import {
  PROJECT_LIST_POST_ID,
  PROJETO_CATEGORY_ID,
} from "../../constants/constants.api";
import { fetchPostsPreview, getPost } from "../../sagas/posts";
import { mapProjectArray } from "../../utils/utils";

const ProjectsSection = ({ onSuccess, projects }) => {
  const [listProjects, setListProjects] = useState([]);
  const fixedProjects = routes.filter((ele) => ele.projectList);

  useEffect(
    () => {
      if (
        projects &&
        projects.length > 0 &&
        projects[0].categories.includes(PROJETO_CATEGORY_ID) &&
        fixedProjects &&
        fixedProjects.length > 0
      ) {
        getPost(PROJECT_LIST_POST_ID, (post) => {
          const listProjects = mapProjectArray(
            post.content.rendered,
            projects,
            fixedProjects
          );
          setListProjects(listProjects);
        });
      }

      fetchPostsPreview(onSuccess, PROJETO_CATEGORY_ID, 1, 30);
    },
    // eslint-disable-next-line
    [onSuccess, projects.length, fixedProjects.length]
  );

  return (
    <div className="home-projects-wrapper">
      <div className="home-projects-header">
        <div className="home-projects-title">Atividades</div>
      </div>
      <div className="home-projects-content">
        {listProjects &&
          listProjects.length > 0 &&
          listProjects.map((ele, index) => {
            const isFixedProject = !!ele.projectList;
            const props = { [isFixedProject ? "fixedPost" : "post"]: ele };
            return (
              <SearchItem
                {...props}
                showImage={index === 0}
                hasBorder
                isProject
              />
            );
          })}
        {/* {fixedProjects &&
          fixedProjects.map((ele, index) => (
            <SearchItem
              fixedPost={ele}
              hasBorder={index < fixedProjects.length - 1}
            />
          ))} */}
      </div>
    </div>
  );
};

export default ProjectsSection;
