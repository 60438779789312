import React from "react";
import "./MapPoints";
import { getNivelAcessibilidade } from "../../utils/utils";

const MyMarker = ({ nivel, titulo, openTitle, setOpenTitle }) => {
  /* const [openTitle, setOpenTitle] = useState(null); */

  const nivelInfo = getNivelAcessibilidade(nivel);
  return (
    <div
      onClick={() => setOpenTitle(openTitle ? null : titulo)}
      className="marker"
      style={{
        backgroundImage: `url(${nivelInfo && nivelInfo.image})`,
      }}
    >
      {!!openTitle && (
        <div className="marker-info">
          <div className="marker-title">{openTitle}</div>
          <div className="marker-level">{nivelInfo && nivelInfo.title}</div>
        </div>
      )}
    </div>
  );
};

export default MyMarker;
