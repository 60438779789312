import { mapSildeshowImage } from "../utils/mappers";

export const fetchPostsPreview = (
  callback,
  categoryNum,
  page,
  perPage,
  callbackAfter = () => {}
) =>
  fetch(
    `https://institutodemobilidade.org/en/wp-json/wp/v2/posts?categories=${categoryNum}&page=${page}&per_page=${perPage}&_embed`
  )
    .then((res) => ({
      result: res.json(),
      total: res.headers.get("X-WP-Total"),
    }))
    .then(
      ({ result, total }) => {
        result.then((posts) => callback(posts, Number(total)));
      },
      (error) => {
        console.log(`Error: ${error}`);
      }
    )
    .then(() => callbackAfter());

export const fetchPostSearchPreview = (
  callback,
  categoryNum,
  search,
  page,
  perPage,
  callbackAfter = () => {}
) =>
  fetch(
    `https://institutodemobilidade.org/en/wp-json/wp/v2/posts?categories=${categoryNum}&search=${search}&page=${page}&per_page=${perPage}&_embed`
  )
    .then((res) => ({
      result: res.json(),
      total: res.headers.get("X-WP-Total"),
    }))
    .then(
      ({ result, total }) => {
        result.then((posts) => callback(posts, Number(total)));
      },
      (error) => {
        console.log(`Error: ${error}`);
      }
    )
    .then(() => callbackAfter());

export const getPost = (id, callback, callbackAfter = () => {}) =>
  fetch(`https://institutodemobilidade.org/en/wp-json/wp/v2/posts/${id}?_embed`)
    .then((res) => res.json())
    .then(
      (result) => {
        callback(result);
      },
      (error) => {
        console.log(`Error: ${error}`);
      }
    )
    .then(() => callbackAfter());

export const fetchPostImages = (id, callback) =>
  fetch(`https://institutodemobilidade.org/en/wp-json/wp/v2/media?parent=${id}`)
    .then((res) => res.json())
    .then((res) => res.map(mapSildeshowImage))
    .then(
      (result) => {
        callback(result);
      },
      (error) => {
        console.log(`Error: ${error}`);
      }
    );
