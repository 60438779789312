import { connectRouter } from "connected-react-router";
import { createStore } from "redux";
import createRootReducer from "../reducers/reducer";
import history from "./history";

const store = createStore(
  connectRouter(history)(createRootReducer),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
