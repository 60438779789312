import React from "react";
import "./NivelAcessibilidade.css";
import { separateNivelText } from "../../utils/utils";

const NivelAcessibilidade = ({ nivel }) => {
  const { nivelNumber, nivelName } = separateNivelText(nivel.title);
  console.log(nivelNumber, nivelName);
  const hasNivelName = !!nivelName;
  console.log(hasNivelName);
  return (
    <div className="nivel-acessibilidade-wrapper">
      <div className="nivel-acessibilidade-text-section">
        <div className="nivel-acessibilidade-text-wrapper">
          <div className="nivel-acessibilidade-title">{nivel.title}</div>
          <div className="nivel-acessibilidade-description">
            {nivel.description}
          </div>
        </div>
      </div>
      <div className="nivel-acessibilidade-triangle" />
      <div className="nivel-acessibilidade-image-section">
        <div className="nivel-acessibilidade-image-wrapper">
          <div
            className="nivel-acessibilidade-image"
            style={{ backgroundImage: `url(${nivel.image})` }}
          />
          <div className="nivel-acessibilidade-nivel-name">
            {hasNivelName ? nivelName : nivelNumber}
          </div>
          {hasNivelName && (
            <div
              className={`nivel-acessibilidade-nivel-number nivel${nivel.id}`}
            >
              {nivelNumber}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NivelAcessibilidade;
