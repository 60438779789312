import React, { useEffect, useState } from "react";
import "./Conferencias.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import {
  fetchPostsSucceeded,
  fetchMorePostsSucceeded,
} from "../../actions/actions";
import { fetchPostsPreview } from "../../sagas/posts";
import { getPostEntries, getPostEntriesTotal } from "../../selectors/posts";
import Button from "../../components/button/Button";
import PageBanner from "../../components/pageBanner/PageBanner";
import { CONFERENCIAS_CATEGORY_ID } from "../../constants/constants.api";
import ConferenciaItem from "../../components/conferenciaItem/ConferenciaItem";
import Loader from "../../components/loader/Loader";

const bannerImage = require("../../assets/banners/conferenciasNew.jpeg");

const PER_PAGE = 9;

const Conferencias = ({
  onFetchPostsSucceeded,
  onFetchMorePostsSucceeded,
  posts,
  total,
}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const callback =
      page === 1 ? onFetchPostsSucceeded : onFetchMorePostsSucceeded;
    fetchPostsPreview(callback, CONFERENCIAS_CATEGORY_ID, page, PER_PAGE, () =>
      setLoading(false)
    );
  }, [onFetchPostsSucceeded, onFetchMorePostsSucceeded, page]);

  const hasMorePages = page * PER_PAGE < total;
  return (
    <div className="conferencias-page">
      <PageBanner
        title="Conferências"
        description="Promoção de eventos nas suas áreas de atuação: conferências, seminários, workshops, tertúlias e Webinars"
        image={bannerImage}
      />
      {posts && posts.length > 0 && (!loading || page > 1) ? (
        <div>
          <div className="conferencias-grid">
            {posts.map((ele, index) => (
              <ConferenciaItem post={ele} key={`conferencias-${index}`} />
            ))}
          </div>
          {hasMorePages &&
            (loading ? (
              <Loader loadMore />
            ) : (
              <div className="conferencias-more-section">
                <Button centered onClick={() => setPage(page + 1)}>
                  CARREGAR MAIS
                </Button>
              </div>
            ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getPostEntries,
  getPostEntriesTotal,
  (posts, total) => ({
    posts,
    total,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
      onFetchMorePostsSucceeded: fetchMorePostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Conferencias);
